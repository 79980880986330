export const DashboardEndPoints = {
  getReportAccessLevel: {
    url: (company, project) =>
      `/rest/v1/visualReportPageAccessLevel/${company}/${project}`,
    method: `GET`,
  },

  contractorsOverviewVisualReport: {
    url: (company, project) =>
      `/rest/v1/contractorsOverviewVisualReport/${company}/${project}`,
    method: `GET`,
  },

  contractorsDetailedAnalysisVisualReport: {
    url: (company, project) =>
      `/rest/v1/contractorsDetailedAnalysisVisualReport/${company}/${project}`,
    method: `GET`,
  },

  entriesStatusAnalysisVisualReport: {
    url: (company, project) =>
      `/rest/v1/entriesStatusAnalysisVisualReport/${company}/${project}`,
    method: `GET`,
  },

  pinpointingProcurementDelaysVisualReport: {
    url: (company, project) =>
      `/rest/v1/pinpointingProcurementDelaysVisualReport/${company}/${project}`,
    method: `GET`,
  },

  usersActivityAnalysisVisualReport: {
    url: (company, project) =>
      `/rest/v1/usersActivityAnalysisVisualReport/${company}/${project}`,
    method: `GET`,
  },

  entriesDistributionAcrossDivisionsReport: {
    url: (company, project) =>
      `/rest/v1/entriesDistributionAcrossDivisionsReport/${company}/${project}`,
    method: `GET`,
  },

  criticalPathItemsReport: {
    url: (company, project) =>
      `/rest/v1/criticalPathItemsReport/${company}/${project}`,
    method: `GET`,
  },

  urgentActionReport: {
    url: (company, project) =>
      `/rest/v1/urgentActionReport/${company}/${project}`,
    method: `GET`,
  },

  deliveriesDueTodayReport: {
    url: (company, project) =>
      `/rest/v1/deliveriesDueTodayReport/${company}/${project}`,
    method: `GET`,
  },

  longLeadTimeStatusReport: {
    url: (company, project) =>
      `/rest/v1/longLeadTimeStatusReport/${company}/${project}`,
    method: `GET`,
  },

  getDashboardCustomization: {
    url: (company, project) =>
      `/rest/v1/getDashboardCustomization/${company}/${project}`,
    method: `GET`,
  },
  updateDashboardCustomization: {
    url: (company, project) =>
      `/rest/v1/updateDashboardCustomization/${company}/${project}`,
    method: `PUT`,
  },

  // modifyIssueDateReport: {
  //   url: (company, project) =>
  //     `/rest/v1/modifyIssueDateReport/${company}/${project}`,
  //   method: `POST`,
  // },

  // deleteCustomReport: {
  //   url: (company, project) =>
  //     `/rest/v1/deleteCustomReport/${company}/${project}`,
  //   method: `DELETE`,
  // },
};
