export const CriticalPathFlagItemsEndPoints = {
  getProcurementLogCriticalPathEntries: {
    url: (company, project) =>
      `/rest/v1/getProcurementLogCriticalPathEntries/${company}/${project}`,
    method: `GET`,
  },

  unflagCriticalPathEntriesFlag: {
    url: (company, project) =>
      `/rest/v1/unflagCriticalPathEntriesFlag/${company}/${project}`,
    method: "DELETE",
  },

  procurementLogFlagCriticalPathEntry: {
    url: (company, project) =>
      `/rest/v1/procurementLogFlagCriticalPathEntry/${company}/${project}`,
    method: "POST",
  },
};
