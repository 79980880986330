import React, { useState, useContext, useMemo, useEffect } from "react";
import {
  Button,
  Switch,
  Table,
  Tooltip,
  Card,
  Typography,
  Spin,
  Checkbox,
  Input,
} from "antd";
import { HolderOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useParams } from "react-router-dom";
import { DashboardServices } from "apis/Services/DashboardService";
import LoadingComponent from "components/loading-component";

const { Text } = Typography;

// Create a context for the row drag handle
const RowContext = React.createContext({});

// Drag handle component
const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: "move" }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

// Sortable row component
const SortableRow = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  const contextValue = useMemo(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners]
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const DashboardCustomization = ({
  setIsShowCustomizeDashboard,
  ApplyDashboardCustomizationChanges,
}) => {
  const [dashboardCustomizationData, setDashboardCusomizationData] = useState(
    {}
  );

  const [leadTimeValue, setLeadTimeValue] = useState();

  const [isLoading, setIsLoading] = useState(true);

  const { company_id, id } = useParams();

  let code = localStorage.getItem("token");
  let email = localStorage.getItem("email");
  let ReqObj = {
    company: company_id,
    project: id,
    code,
    loginEmail: email,
  };

  function getDashboardCustomization() {
    DashboardServices.getDashboardCustomization(ReqObj)
      .then((res) => {
        // console.log(res?.content);
        setDashboardCusomizationData(res?.content?.dashboardReportsInfo);
        setPersonalizedSummaryVisible(
          res?.content?.dashboardReportsInfo?.usersActivityAnalysisVisualReport
            ?.visibility
        );
        setLeadTimeValue(parseInt(res?.content?.longLeadTime));
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally();
  }

  function updateDashboardCustomization(newDashboardData) {
    DashboardServices.updateDashboardCustomization(newDashboardData, ReqObj)
      .then((res) => {
        // console.log(res?.content);
        // setDashboardCusomizationData(res?.content?.dashboardReportsInfo);
        setIsShowCustomizeDashboard(false);
        ApplyDashboardCustomizationChanges();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally();
  }

  useEffect(() => {
    getDashboardCustomization();
  }, []);

  const [tableData, setTableData] = useState([]);

  const [hasChanges, setHasChanges] = useState(false);

  const [allVisible, setAllVisible] = useState(true);

  const [personalizedSummaryVisible, setPersonalizedSummaryVisible] =
    useState();

  // Convert object to array when dashboardCustomizationData changes
  useEffect(() => {
    const excludedKeys = [
      // "criticalPathStatusReport",
      "usersActivityAnalysisVisualReport",
    ];

    if (dashboardCustomizationData) {
      setPersonalizedSummaryVisible(
        dashboardCustomizationData?.usersActivityAnalysisVisualReport
          ?.visibility
      );

      const dataArray = Object.entries(dashboardCustomizationData)
        .filter(([key]) => !excludedKeys.includes(key))
        .map(([key, value]) => ({
          key,
          id: key,
          name: value.label,
          index: parseInt(value.index),
          visible: value.visibility === "1",
        }))
        .sort((a, b) => a.index - b.index);

      setTableData(dataArray);
      setAllVisible(dataArray.every((item) => item.visible));
    }
  }, [dashboardCustomizationData]);

  // Update the dashboardCustomizationData state when table data changes
  const updateDashboardData = (newTableData) => {
    const allReportKeys = [
      "deliveriesDueTodayReport",
      "longLeadTimeStatusReport",
      "urgentActionReport",
      "entriesStatusAnalysisVisualReport",
      "entriesDistributionAcrossDivisionsReport",
      "contractorsOverviewVisualReport",
      "contractorsDetailedAnalysisVisualReport",
      "pinpointingProcurementDelaysVisualReport",
      "usersActivityAnalysisVisualReport",
      "criticalPathStatusReport",
    ];

    const transformedData = {};

    transformedData["usersActivityAnalysisVisualReport"] = {
      index: "0", // Always first
      visibility: personalizedSummaryVisible ? "1" : "-1",
      label: "Personalized Summary",
    };

    // Convert table data into the required format
    newTableData.forEach(({ key, name, index, visible }) => {
      transformedData[key] = {
        index: index,
        visibility: visible ? "1" : "-1",
      };
    });

    // Ensure all expected keys are present, setting visibility to "-1" if missing
    allReportKeys.forEach((reportKey, index) => {
      if (!transformedData[reportKey]) {
        transformedData[reportKey] = {
          index: "-1",
          visibility: "-1",
        };
      }
    });

    // Final object with the required format
    const newDashboardData = {
      dashboardReportsInfo: transformedData,
      longLeadTime: null,
    };

    updateDashboardCustomization(newDashboardData);
  };

  const handleVisibilityToggle = (key) => {
    const newData = tableData.map((item) => {
      if (item.key === key) {
        return { ...item, visible: !item.visible };
      }
      return item;
    });

    setTableData(newData);
    setAllVisible(newData.every((item) => item.visible));
    setHasChanges(true);
  };

  const handlePersonalizedSummaryToggle = (checked) => {
    setPersonalizedSummaryVisible(checked);
    setHasChanges(true);
  };

  const toggleAllVisibility = (visible) => {
    const newData = tableData.map((item) => ({
      ...item,
      visible,
    }));

    setTableData(newData);
    setAllVisible(visible);
    setHasChanges(true);
  };

  // Handle drag end event
  const onDragEnd = ({ active, over }) => {
    if (!over || active.id === over.id) return;

    setTableData((prevData) => {
      const activeIndex = prevData.findIndex((item) => item.key === active.id);
      const overIndex = prevData.findIndex((item) => item.key === over.id);

      // Create new array with the dragged item moved
      const newData = arrayMove(prevData, activeIndex, overIndex);

      // Update indexes to match new positions
      const updatedData = newData.map((item, index) => ({
        ...item,
        index,
      }));

      setHasChanges(true);
      return updatedData;
    });
  };

  // Define table columns
  const columns = [
    {
      key: "sort",
      width: 50,
      render: () => <DragHandle />,
    },
    {
      title: "Report Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span style={{ marginRight: 8 }}>Visibility</span>
          <Tooltip title="Toggle All">
            <Switch
              checked={allVisible}
              onChange={toggleAllVisibility}
              size="small"
            />
          </Tooltip>
        </div>
      ),
      key: "visible",
      align: "center",
      width: 120,
      render: (_, record) => (
        <Switch
          checked={record.visible}
          onChange={() => handleVisibilityToggle(record.key)}
        />
      ),
    },
  ];

  // Handle save button click
  const handleSave = () => {
    updateDashboardData(tableData);
    setHasChanges(false);
  };

  // useEffect(() => {
  //   console.log(tableData, "!!!");
  // }, [tableData]);

  return (
    <>
      <h3 className=" fw-bold m-0 p-0">Dashboard Customization</h3>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          {/* <hr /> */}
          <Text type="secondary" style={{ marginBottom: 16, display: "block" }}>
            Drag reports to reorder them and toggle visibility as needed.
            Changes will be reflected on your dashboard after saving.
          </Text>
          <DndContext
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
          >
            <SortableContext
              items={tableData.map((item) => item.key)}
              strategy={verticalListSortingStrategy}
            >
              <Table
                bordered
                dataSource={tableData}
                columns={columns}
                components={{
                  body: {
                    row: SortableRow,
                  },
                }}
                pagination={false}
                rowKey="key"
              />
            </SortableContext>
          </DndContext>

          <div className=" d-flex justify-content-start align-items-center my-4">
            <div className=" me-4">
              <Text style={{ fontSize: "0.9rem" }}>
                Show Personalized Summary
              </Text>
            </div>
            <Switch
              checked={personalizedSummaryVisible}
              onChange={handlePersonalizedSummaryToggle}
            />
          </div>

          {/* <div className=" d-flex justify-content-start align-items-center my-4">
            <div className=" me-4 d-flex gap-3">
              <Text style={{ fontSize: "0.9rem" }}>Lead Time Value</Text>
              <Input
                id="leadTimeValue"
                style={{ width: "50px" }}
                type="number"
                size="small"
                min="0"
                step="1"
                onChange={(e) => {
                  setLeadTimeValue(e.target.value);
                  setHasChanges(true);
                }}
                defaultValue={leadTimeValue || 8}
                onKeyDown={(e) => {
                  if (e.key === "." || e.key === "e") e.preventDefault();
                }}
              />
            </div>
          </div> */}

          {/* <hr /> */}
          <div className=" d-flex justify-content-end mt-3">
            <Button type="primary" onClick={handleSave} disabled={!hasChanges}>
              Save Changes
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default DashboardCustomization;
