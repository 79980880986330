import { Pagination, Popover, Radio, Select, Space } from "antd";
import React, { useEffect, useState } from "react";

import ArrowDownIcon from "assets/images/arrow-down-blue.svg";
import PersonIcon from "assets/images/person-icon.svg";
import { SideBarEndService } from "apis/Services/SideBarService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styles from "./table.module.css";
import { useParams } from "react-router-dom";
import LoadingComponent from "components/loading-component";

/* Table MUI */

// function createData(time, person, division, spec, update) {
//   return { time, person, division, spec, update };
// }

function createData(time, person, division, spec, update, category) {
  return { time, person, division, spec, update, category };
}

//

const NotificationTable = ({ company_id, project_id }) => {
  const [totalActivities, setTotalActivities] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [actions, setActions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let code = localStorage.getItem("token");
  let email = localStorage.getItem("email");

  useEffect(() => {
    let data = {
      code,
      loginEmail: email,
      project_id,
      company_id,
      Page: currentPage,
      Per_Page: perPage,
    };

    SideBarEndService.NotificationCenter(data)
      .then((res) => {
        setTotalActivities(res?.totalActivitiesCnt);
        setActions(res?.activityTracker);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  }, [currentPage, perPage]);

  const [FilterValues, setFilterValues] = useState("");

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setIsLoading(true);
  };

  const handlePerPageChange = (value) => {
    setPerPage(value);
    setCurrentPage(1);
    setIsLoading(true);
  };

  const filteredData = actions.filter((row) => {
    if (
      FilterValues.includes(row[1]) ||
      FilterValues.includes(row[2]) ||
      FilterValues.includes(row[5])
    ) {
      return true;
    }
    return false;
  });

  // console.log(filteredData, "filteredData");
  // console.log(FilterValues, "FilterValues");
  return (
    <div style={{ position: "relative" }}>
      <TableContainer
        className={styles.tableContainer}
        style={{ height: "90vh" }}
      >
        {!isLoading ? (
          <>
            <ul className={styles.tableFilterSection}>
              <li>
                <span>Activity</span>
              </li>
              <li style={{ width: "100%" }}>
                <FilterContainer
                  data={actions}
                  setFilterValues={setFilterValues}
                />
              </li>
            </ul>
            <Table
              sx={{ minWidth: 750 }}
              stickyHeader
              aria-label="caption table"
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "13%" }}>Date</TableCell>
                  <TableCell style={{ width: "10%" }}>Person</TableCell>
                  <TableCell style={{ width: "20%" }}>Spec Title</TableCell>
                  <TableCell style={{ width: "15%" }}>Spec Number</TableCell>
                  <TableCell style={{ width: "40%" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {actions.map((row, index) => (
                  <CustomTableRow key={index} data={row} />
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <div
            style={{ minWidth: 750 }}
            className=" d-flex justify-content-center align-items-center"
          >
            <LoadingComponent />
          </div>
        )}
      </TableContainer>

      {/* Sticky Pagination & Per Page Selector */}
      <div
        style={{
          position: "sticky",
          bottom: 0,
          background: "white",
          padding: "10px 0",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          borderTop: "1px solid #ddd",
          zIndex: 1000,
        }}
      >
        {/* Pagination */}
        <Pagination
          current={currentPage}
          total={totalActivities}
          pageSize={perPage}
          onChange={handlePageChange}
          showSizeChanger={false}
          // style={{ marginRight: "20px", padding: "0px" }}
        />
        <Select
          defaultValue={perPage}
          style={{ width: 130 }}
          onChange={handlePerPageChange}
          options={[
            { value: 25, label: "25 / per page" },
            { value: 50, label: "50 / per page" },
            { value: 100, label: "100 / per page" },
            { value: 200, label: "200 / per page" },
          ]}
        />
      </div>
    </div>
  );
};

const CustomTableRow = ({ data }) => {
  const fullName = data[5];
  const wordsArray = fullName.split(" ");

  let initials = "";

  for (const word of wordsArray) {
    initials += word.charAt(0);
  }

  const dateTimeString = data[6];
  const [datePart, timePart] = dateTimeString.split(" ");

  const [month, day, year] = datePart.split("-");
  const [hours, minutes, seconds] = timePart.split(":");

  const utcDate = new Date(
    Date.UTC(year, month - 1, day, hours, minutes, seconds)
  );

  const userDate = new Date(utcDate.toLocaleString());

  // Get the timezone name
  const timeZoneName = userDate
    .toLocaleString("en", { timeZoneName: "short" })
    .split(" ")[3];

  let formattedDate = userDate.toLocaleDateString();
  let formattedTime = userDate.toLocaleTimeString();

  formattedDate = formattedDate.replace(/\//g, "-");

  // console.log(formattedTime);

  return (
    <TableRow>
      <TableCell className={styles.firstCell}>
        {formattedDate} <br /> {formattedTime} <br />{" "}
        <span
          style={{
            color: "gray",
            fontSize: "13px",
          }}
        >
          {timeZoneName}
        </span>
      </TableCell>
      <Popover content={data[5]}>
        <TableCell
          className={styles.personCell}
          sx={{
            color: "#fff",
            textAlign: "center",
            display: "flex",
          }}
        >
          {initials}
        </TableCell>
      </Popover>
      <Popover content={data[1]}>
        <TableCell>{data[1]}</TableCell>
      </Popover>

      <Popover content={data[2]}>
        <TableCell>{data[2]}</TableCell>
      </Popover>
      <Popover content={data[7]}>
        <TableCell>{data[7]}</TableCell>
      </Popover>
    </TableRow>
  );
};

const FilterContainer = ({ data, setFilterValues }) => {
  const convertedTestData = data?.map((item) => ({
    ID: item[0],
    ["Spec Title"]: item[1],
    ["Spec Number"]: item[2],
    SubDivName: item[3],
    unknown: item[4],
    Person: item[5],
    dateTime: item[6],
    Action: item[7],
  }));

  const uniqueKeys = convertedTestData[0]
    ? Object.keys(convertedTestData[0])?.filter(
        (key) =>
          key !== "ID" &&
          key !== "SubDivName" &&
          key !== "dateTime" &&
          key !== "Action" &&
          key !== "unknown"
      )
    : [];

  const [selectedKeys, setSelectedKeys] = useState([]);

  const handleKeySelect = (values) => {
    setSelectedKeys(values);
    // setChosenName(values);
  };

  const handleValueSelect = (values) => {
    setFilterValues(values);
  };

  const selectedValues = selectedKeys.reduce((values, key) => {
    const keyValues = convertedTestData.map((item) => item[key]);
    return [...values, ...keyValues];
  }, []);

  // Filter unique values
  const uniqueSelectedValues = [...new Set(selectedValues)];

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <Space
        direction="horizontal"
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Select
          mode="multiple"
          size={"middle"}
          placeholder="Filter"
          onChange={handleKeySelect}
          style={{
            width: "100%",
            minWidth: "150px",
          }}
          options={uniqueKeys.map((key) => ({
            value: key,
            label: key,
          }))}
        />

        {/*  */}
        {/* {selectedKeys.length > 0 && (
          <Select
            mode="multiple"
            size={"middle"}
            placeholder="Select values"
            onChange={handleValueSelect}
            style={{
              width: "100%",
              minWidth: "150px",
            }}
            options={selectedKeys.map((key) => ({
              label: key,
              options: uniqueSelectedValues
                .filter((value) =>
                  convertedTestData.some((item) => item[key] == value)
                )
                .map((value) => ({
                  value,
                  label: value,
                })),
            }))}
          />
        )} */}
        {/*  */}

        {selectedKeys.length > 0 && (
          <Select
            mode="multiple"
            size={"middle"}
            placeholder="Select values"
            onChange={handleValueSelect}
            style={{
              width: "100%",
              minWidth: "150px",
            }}
            options={selectedKeys.map((key, index) => ({
              label: key,
              key: `${key}-${index}-${Math.floor(
                Math.random() * 10000
              ).toString()}`,
              options: uniqueSelectedValues
                .filter((value) =>
                  convertedTestData.some((item) => item[key] == value)
                )
                .map((value, index) => ({
                  value,
                  label: value,
                  key: `${key}-${index}-${Math.floor(
                    Math.random() * 10000
                  ).toString()}`,
                })),
            }))}
          />
        )}
      </Space>
    </div>
  );
};

export default NotificationTable;
