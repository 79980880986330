import { Network } from "../Network";
import { SideBarEndPoints } from "apis/EndPoints/SideBarEndPoints";

export class SideBarEndService {
  static TasksAssignedInfo(data = {}) {
    return Network.fetch(
      SideBarEndPoints.TasksAssigned.url(data?.company_id, data?.project_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
          "if-match": data?.id,
        },
        method: SideBarEndPoints.TasksAssigned.method,
        // body: JSON.stringify(data),
      }
    );
  }

  static assignTask(data = {}) {
    return Network.fetch(
      SideBarEndPoints.assignTasks.url(data?.company_id, data?.project_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: SideBarEndPoints.assignTasks.method,
        body: JSON.stringify(data),
      }
    );
  }

  static deleteTask(data = []) {
    return Network.fetch(
      SideBarEndPoints.deleteTasks.url(
        data[0]?.company_id,
        data[0]?.project_id
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data[0]?.code,
          From: data[0]?.loginEmail,
        },
        method: SideBarEndPoints.deleteTasks.method,
        body: JSON.stringify(data),
      }
    );
  }

  static completeTask(data = {}) {
    return Network.fetch(
      SideBarEndPoints.completeTasks.url(data?.company_id, data?.project_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: SideBarEndPoints.completeTasks.method,
        body: JSON.stringify(data),
      }
    );
  }
  static NotificationCenter(data = {}) {
    return Network.fetch(
      SideBarEndPoints.procurementLogNotificationCenter.url(
        data?.company_id,
        data?.project_id
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
          Page: data?.Page,
          Per_Page: data?.["Per_Page"],
        },
        method: SideBarEndPoints.procurementLogNotificationCenter.method,
      }
    );
  }
}
