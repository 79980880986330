import { Network } from "../Network";
import { DashboardEndPoints } from "apis/EndPoints/DashboardEndPoints";
import { useHandleQueryParams } from "hooks/useHandleQueryParam";

export class DashboardServices {
  static getReportAccessLevel(data = {}) {
    return Network.fetch(
      DashboardEndPoints.getReportAccessLevel.url(data?.company, data?.project),
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: DashboardEndPoints.getReportAccessLevel.method,
      }
    );
  }

  static contractorsOverviewVisualReport(data = {}) {
    return Network.fetch(
      DashboardEndPoints.contractorsOverviewVisualReport.url(
        data?.company,
        data?.project
      ),
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: DashboardEndPoints.contractorsOverviewVisualReport.method,
      }
    );
  }

  static contractorsDetailedAnalysisVisualReport(data = {}) {
    return Network.fetch(
      DashboardEndPoints.contractorsDetailedAnalysisVisualReport.url(
        data?.company,
        data?.project
      ),
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method:
          DashboardEndPoints.contractorsDetailedAnalysisVisualReport.method,
      }
    );
  }

  static entriesStatusAnalysisVisualReport(data = {}) {
    return Network.fetch(
      DashboardEndPoints.entriesStatusAnalysisVisualReport.url(
        data?.company,
        data?.project
      ),
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: DashboardEndPoints.entriesStatusAnalysisVisualReport.method,
      }
    );
  }

  static criticalPathItemsReport(data = {}) {
    return Network.fetch(
      DashboardEndPoints.criticalPathItemsReport.url(
        data?.company,
        data?.project
      ),
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: DashboardEndPoints.criticalPathItemsReport.method,
      }
    );
  }

  static entriesBottleNeckAnalysisVisualReport(data = {}) {
    return Network.fetch(
      DashboardEndPoints.pinpointingProcurementDelaysVisualReport.url(
        data?.company,
        data?.project
      ),
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method:
          DashboardEndPoints.pinpointingProcurementDelaysVisualReport.method,
      }
    );
  }

  static usersActivityAnalysisVisualReport(data = {}) {
    return Network.fetch(
      DashboardEndPoints.usersActivityAnalysisVisualReport.url(
        data?.company,
        data?.project
      ),
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: DashboardEndPoints.usersActivityAnalysisVisualReport.method,
      }
    );
  }

  static entriesDistributionAcrossDivisionsReport(data = {}) {
    return Network.fetch(
      DashboardEndPoints.entriesDistributionAcrossDivisionsReport.url(
        data?.company,
        data?.project
      ),
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method:
          DashboardEndPoints.entriesDistributionAcrossDivisionsReport.method,
      }
    );
  }

  static urgentActionReport(data = {}) {
    return Network.fetch(
      DashboardEndPoints.urgentActionReport.url(data?.company, data?.project),
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: DashboardEndPoints.urgentActionReport.method,
      }
    );
  }

  static deliveriesDueTodayReport(data = {}) {
    return Network.fetch(
      DashboardEndPoints.deliveriesDueTodayReport.url(
        data?.company,
        data?.project
      ),
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: DashboardEndPoints.deliveriesDueTodayReport.method,
      }
    );
  }

  static longLeadTimeStatusReport(data = {}, leadTimeValue) {
    return Network.fetch(
      DashboardEndPoints.longLeadTimeStatusReport.url(
        data?.company,
        data?.project
      ),
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: data?.code,
          From: data?.loginEmail,
          minValue: leadTimeValue,
        },
        method: DashboardEndPoints.longLeadTimeStatusReport.method,
      }
    );
  }

  static getDashboardCustomization(data = {}) {
    return Network.fetch(
      DashboardEndPoints.getDashboardCustomization.url(
        data?.company,
        data?.project
      ),
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: DashboardEndPoints.getDashboardCustomization.method,
      }
    );
  }

  static updateDashboardCustomization(data = {}, ReqObj = {}) {
    return Network.fetch(
      DashboardEndPoints.updateDashboardCustomization.url(
        ReqObj?.company,
        ReqObj?.project
      ),
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: ReqObj?.code,
          From: ReqObj?.loginEmail,
        },
        method: DashboardEndPoints.updateDashboardCustomization.method,
        body: JSON.stringify(data),
      }
    );
  }
}
