import { Network } from "../Network";
import { CriticalPathFlagItemsEndPoints } from "apis/EndPoints/CriticalPathFlagItemsEndPoints";

export class CriticalPathFlagItemsService {
  static getProcurementLogCriticalPathEntries(data = {}) {
    return Network.fetch(
      CriticalPathFlagItemsEndPoints.getProcurementLogCriticalPathEntries.url(
        data?.company_id,
        data.id
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method:
          CriticalPathFlagItemsEndPoints.getProcurementLogCriticalPathEntries
            .method,
      }
    );
  }

  static unflagCriticalPathEntriesFlag(data = {}) {
    return Network.fetch(
      CriticalPathFlagItemsEndPoints.unflagCriticalPathEntriesFlag.url(
        data?.company_id,
        data.id
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method:
          CriticalPathFlagItemsEndPoints.unflagCriticalPathEntriesFlag.method,
        body: JSON.stringify(data),
      }
    );
  }

  static procurementLogFlagCriticalPathEntry(data = {}) {
    return Network.fetch(
      CriticalPathFlagItemsEndPoints.procurementLogFlagCriticalPathEntry.url(
        data?.company_id,
        data.id
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method:
          CriticalPathFlagItemsEndPoints.procurementLogFlagCriticalPathEntry
            .method,
        body: JSON.stringify(data),
      }
    );
  }
}
