import * as yup from "yup";

import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";

import { AuthService } from "apis/Services/AuthService";
import AuthWrapper from "components/auth-page-wrapper";
import ButtonComponent from "components/button-component";
import InputComponent from "components/input-component";
import styles from "./reset.module.css";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const CreatePassword = () => {
  const [showPassword, setshowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);

  const navigate = useNavigate();
  const { code } = useParams();
  let schema = yup.object().shape({
    newPassword1: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])/,
        "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number , and 1 symbol"
      ),

    newPassword2: yup
      .string()
      .required("This field is required")
      .oneOf([yup.ref("newPassword1"), null], "Field should match password"),
  });

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    defaultValues: {
      newPassword1: "",
      newPassword2: "",
    },
    resolver: yupResolver(schema),
    mode: "all",
  });
  const onSubmit = (v) => {
    AuthService.resetPassword({ ...v, verificationCode: code })
      .then((res) => {
        toast.success("Your password has been updated successfully!");
        navigate(`/login`);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(
          "Link expired, please a request a new password reset link!"
        );
      });
  };
  return (
    <AuthWrapper title="Create Password">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-column mt-3 ">
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <Controller
                name="newPassword1"
                control={control}
                render={({ field }) => (
                  <InputComponent
                    label="New Password"
                    errors={errors.newPassword1}
                    normalError
                    placeholder="Enter your new password"
                    type={showPassword ? "text" : "password"}
                    {...field}
                  />
                )}
              />
            </div>
            <div
              className="pointer p-3 pt-3 mt-1"
              onClick={() => setshowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </div>
          </div>

          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <Controller
                name="newPassword2"
                control={control}
                render={({ field }) => (
                  <InputComponent
                    label="Confirm Password"
                    errors={errors.newPassword2}
                    normalError
                    placeholder="Confirm your password"
                    type={showConfirmPassword ? "text" : "password"}
                    {...field}
                  />
                )}
              />
            </div>
            <div
              className="pointer p-3 pt-3 mt-1"
              onClick={() => setshowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </div>
          </div>

          <ButtonComponent
            title={"Create Password"}
            className={`mt-3`}
            disabled={!isValid}
          />
        </div>
      </form>
    </AuthWrapper>
  );
};

export default CreatePassword;
